import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/layout";

import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";

import FigureList, {
  articlesFigureListAdapter,
} from "../components/Molecules/FigureList";
import Newsletter, {
  adapter as newsletterAdapter,
} from "../components/Molecules/Newsletter";
import StickyWrapper from "../components/Organisms/StickyWrapper";
import HeroImage, {
  adapter as heroAdapter,
} from "../components/Molecules/HeroImage";
import {
  StyledSearchBox,
  SearchResult,
  CustomStateNoResult,
} from "../components/Molecules/Search";

import { withTrans } from "../i18n/withTrans";
import { colors } from "../../styles/theme";

import { adapter as seoAdapter } from "../components/seo";
import { createURL, urlToSearchState } from "../utils/url";
import { configAdapter } from "../utils/configAdapter";

const searchIndices = [
  {
    name: `${process.env.GATSBY_WEBSITE}_INDEX`,
  },
];

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  {
    _useRequestCache: true,
  }
);

const searchClient = {
  search(requests) {
    const shouldSearch = requests.some(
      ({ params: { query } }) => query.length > 2
    );
    if (shouldSearch) {
      return algoliaClient.search(requests);
    }
    return Promise.resolve({
      results: [{ hits: [] }],
    });
  },
  searchForFacetValues: algoliaClient.searchForFacetValues,
};

const SearchPage = ({ data, location, pageContext }) => {
  const { t } = useTranslation();

  const uid = data.prismicSearch?.uid;
  const prismicPageContent = { uid, ...data?.prismicSearch?.data };
  const prismicSiteConfiguration = data.allPrismicSiteConfiguration?.edges?.find(
    ({ node }) => node.data.site === pageContext.website
  ).node.data;

  const prismicLastArticlesContent = data.allPrismicArticle?.edges;
  const prismicLastArticlesPushContent = prismicPageContent.articles_push;

  const siteConfig = React.useMemo(
    () =>
      prismicSiteConfiguration &&
      configAdapter(prismicSiteConfiguration?.config),
    [prismicSiteConfiguration]
  );

  const [hasFocus, setHasFocus] = useState(false);

  const handleFocusInput = useCallback(() => {
    setHasFocus(true);
  }, []);

  useEffect(() => {
    hasFocus && setHasFocus(false);
  }, [hasFocus]);

  return (
    <Layout
      seoData={seoAdapter(data?.prismicSearch)}
      location={location}
      handleFocusInput={handleFocusInput}
      isSearch
    >
      <InstantSearch
        searchClient={searchClient}
        indexName={searchIndices[0].name}
        createURL={createURL}
      >
        <StickyWrapper
          data={prismicPageContent}
          catId={uid}
          component={
            <>
              <HeroImage {...heroAdapter(prismicPageContent)} isSticky />

              <StyledSearchBox
                initialSearch={urlToSearchState(location).query}
                handleOnFocusInput={hasFocus}
              />
            </>
          }
        >
          <CustomStateNoResult>
            <SearchResult indices={searchIndices} config={siteConfig} />
          </CustomStateNoResult>
          {prismicLastArticlesContent && (
            <FigureList
              {...articlesFigureListAdapter({
                lastArticles: prismicLastArticlesContent,
                articlesPush: prismicLastArticlesPushContent,
                maxitems: 3,
              })}
              title={t("tc_articlepush")}
              columns={3}
              bgColor={colors.black}
            />
          )}
          <Newsletter
            {...newsletterAdapter({ template: "troiscouleurs" }, { t })}
          />
        </StickyWrapper>
      </InstantSearch>
    </Layout>
  );
};

export const query = graphql`
  query searchPageTroiscouleursQuery {
    ...i18n
    ...InternalArticles
    ...Configuration
    ...SearchTroiscouleurs
  }
`;

SearchPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

SearchPage.defaultProps = {
  data: {},
};

export default withPreview(withTrans(SearchPage));
